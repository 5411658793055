import { Action, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { RejectedShipmentsStateModel } from '@store/mobile/rejected-shipments/rejected-shipments-state.model';
import {
  ResetRejectedReasonsAndBoxes,
  ResetRejectedShipments,
  ScanRejectedShipment,
  SetShipmentInformation,
  SetRejectedShipment,
  SetRejectedReasonsAndBoxes,
} from '@store/mobile/rejected-shipments/rejected-shipments.actions';

@State<Partial<RejectedShipmentsStateModel>>({
  name: 'rejectedShipments',
  defaults: {
    rejectedBoxes: {},
  },
})
@Injectable()
export class RejectedShipmentsState {
  @Action(SetShipmentInformation)
  public setShipmentInformation(
    ctx: StateContext<RejectedShipmentsStateModel>,
    { information }: RejectedShipmentsStateModel,
  ): void {
    const state = ctx.getState();

    ctx.setState({
      ...state,
      information,
    });
  }

  @Action(SetRejectedShipment)
  public setRejectedShipment(
    ctx: StateContext<Partial<RejectedShipmentsStateModel>>,
    { shipment, information }: SetRejectedShipment,
  ): void {
    ctx.patchState({
      shipment,
      information,
    });
  }

  @Action(ScanRejectedShipment)
  public setScannedCode(ctx: StateContext<RejectedShipmentsStateModel>, { shipmentId }: ScanRejectedShipment): void {
    const state = ctx.getState();

    ctx.setState({
      ...state,
      shipmentId,
    });
  }

  @Action(SetRejectedReasonsAndBoxes)
  public setRejectedBoxes(
    ctx: StateContext<Partial<RejectedShipmentsStateModel>>,
    { boxStockId, reasonId, amount }: SetRejectedReasonsAndBoxes,
  ): void {
    const state = ctx.getState();
    const rejectedBoxes = { ...state.rejectedBoxes };

    if (!rejectedBoxes[boxStockId]) {
      rejectedBoxes[boxStockId] = { [reasonId]: 0 };
    }

    let boxes = rejectedBoxes[boxStockId][reasonId];

    if (boxes) {
      boxes = boxes + amount;
    } else {
      boxes = amount;
    }

    ctx.patchState({
      ...state,
      rejectedBoxes: {
        ...rejectedBoxes,
        [boxStockId]: {
          ...rejectedBoxes[boxStockId],
          [reasonId]: boxes,
        },
      },
    });
  }

  @Action(ResetRejectedReasonsAndBoxes)
  public resetRejectedBoxes(
    ctx: StateContext<Partial<RejectedShipmentsStateModel>>,
    { boxStockId, reasonId }: ResetRejectedReasonsAndBoxes,
  ): void {
    const state = ctx.getState();
    const rejectedBoxes = { ...state.rejectedBoxes };

    ctx.patchState({
      ...state,
      rejectedBoxes: {
        ...rejectedBoxes,
        [boxStockId]: {
          ...rejectedBoxes[boxStockId],
          [reasonId]: undefined,
        },
      },
    });
  }

  @Action(ResetRejectedShipments)
  public reset(ctx: StateContext<Partial<RejectedShipmentsStateModel>>): void {
    ctx.setState({
      rejectedBoxes: {},
    });
  }
}
